@keyframes anim {
  0% {
    background-color: #ff0000;
  }
  25% {
    background-color: #f80;
  }
  50% {
    background-color: rgb(208, 16, 196);
  }
  75% {
    background-color: #f80;
  }
  100% {
    background-color: red;
  }

}

.alertPatient {
  animation-name: anim;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  width: '100%';
}